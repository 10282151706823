
import components from './components/index.js';
import dialogs from './dialogs/index.js';
import pages from './pages/index.js';
import appcaches from './caches/index.js';
// import plugins from './plugins/index.js';

import package_json from '../package.json';

/* Import local css */
import './css/queries.css';

(function() {

	window.Grape.modules.push(package_json);

	Grape.dialog.registerDialogs(dialogs);
	Grape.component.registerComponents(components);
	Grape.pages.registerPages(pages);
	Grape.cache.registerCaches(appcaches);

	// Grape.plugins.registerPlugins(plugins);

})();
