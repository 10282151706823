import template from './search-all-queries.html';

class SearchQueriesViewModel
{
	constructor (params)
	{
		this.params = params;

		this.queries = ko_helper.safe_observableArray();
		this.selected_query = ko_helper.safe_observable(params.selected_query || null);
		this.search_string = ko.observable('');
		this.display_text = ko.observable();

		this.selected_query.subscribe((val) => {
			console.log('slected query', val);
			if (this.selected_query() == null)
				this.display_text(null);

			this.queries([]);
		});

		this.search_string.subscribe((newValue) => {
			this.updateData();
		});
	}

	async init()
	{
		console.log('TESTING SEARCH COMPONENT');
		await this.updateData();
	}

	async updateData()
	{
		let options = {
			table: 'v_queries',
			schema: 'queries',
			limit: 1000000,
			filter: []
		};

		if (this.search_string() && this.search_string() != '')
		{
			options.filter.push({
				field: 'query_refnr',
				operand: 'ILIKE',
				value: `%${this.search_string()}%`,
			});

			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR')
				if (result.records != null)
					this.queries(result.records);
		}
		else
			this.queries([]);

		if (this.queries().length == 0 && this.search_string())
			this.selected_query(this.search_string());
	}

	query_click (v, e)
	{
		this.selected_query(v);
	}
}

export default {
	name: 'ko-search-queries',
	viewModel: SearchQueriesViewModel,
	module_type: 'ko',
	template: template
};