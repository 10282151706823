/**
 * @kind cache
 * @class QueryStatesLookup
 * @description Custom lookup cache for all available query states
 * @usage Grape.cache.fetch('QueryStatesLookup', function(data) { });
 */
export default {
	name: 'QueryStatesLookup',
	options: {
		schema: 'states',
		table: 'v_flow_states',
		filter_join: 'AND',
		filter:[{'field':'flow_description', 'operator':'=', 'value':'Contact queries'}]
	}
};