
// COMPONENTS
// import sample_component from './sample-component/sample-component.js';

// SEARCH
import search_all_queries from './search-all-queries/search-all-queries.js';
import query_conversation from './query-conversation/query-conversation.js';
import contact_queres_panel from './contact-queries-panel/contact-queries-panel.js';
import contact_queries from './contact-queries/contact-queries.js';
import shipments from './shipments/shipments.js';
import shipments_panel from './shipments-panel/shipments-panel.js';

export default [
	search_all_queries,
	query_conversation,
	contact_queres_panel,
	contact_queries,
	shipments,
	shipments_panel
];
