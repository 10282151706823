import template from './contact-queries-panel.html';

class ContactQueriesPanelViewModel
{
	constructor(params)
	{
		this.params = params;
		this.contact_uuid = ko_helper.safe_observable(params.contact_uuid);
		this.contact = ko_helper.safe_observable(params.contact);
		this.queries = ko.observableArray();

		this.contact_uuid.subscribe((newValue) => {
			this.get_queries(newValue);
		});

		this.init();
	}

	async init()
	{
		console.log('CONTACT:', this.contact());
		if (this.contact_uuid())
			this.get_queries();
		else if (this.contact())
		{
			let uuid = this.contact().contact_uuid;
			this.contact_uuid(uuid);
		}
	}

	async get_queries()
	{
		if (!this.contact_uuid()) return;

		let res = await Grape.fetches.getJSON('/api/record', {
			table: 'v_queries',
			schema: 'queries',
			filter: [{
				field: 'contact_uuid',
				operand: '=',
				value: this.contact_uuid()
			}]
		});
		if (res.status == 'ERROR')
			Grape.alerts.apiError(res);
		else if (res.status == 'OK')
			this.queries(res.records);
		else
			Grape.alerts.alert({ type: 'error', message: 'Error occurred while loading the contact queries', title: ''});
	}

	btn_nav_to_query(data)
	{
		console.log('data query:', data);
		Grape.navigate('[/ui/engagements/]query', {data});
	}
}


export default {
	name: 'ko-contact-queries-panel',
	viewModel: ContactQueriesPanelViewModel,
	module_type: 'ko',
	template: template
}