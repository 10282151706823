import template from './shipments-panel.html';

class ShipmentsPanelViewModel
{
	constructor(params)
	{
		this.params = params;
		this.contact = ko_helper.safe_observable(params.contact);
		this.name = ko_helper.safe_observable(params.name);
	}

	async init()
	{

	}

	async get_shipments(contact)
	{
		// api to get shipments
	}
}


export default {
	name: 'ko-shipments-panel',
	viewModel: ShipmentsPanelViewModel,
	module_type: 'ko',
	template: template
}