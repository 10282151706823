class Query {
	constructor(obj)
	{
		this.categories = ko.observableArray([]);
		this.query = ko.observable();
		this.state_item = ko.observable();
		this.message = ko.observable();
		this.contact = ko.observable();
		this.query_refnr = ko.observable();
		this.channel = ko.observable();
		this.assignee = ko.observable();
		this.state = ko.observable();
		this.contact_text = ko.computed(()=>{
			let contact = this.contact() || {};
			let contact_text = [];
			if (contact.hasOwnProperty('personal_detail')){
				if (contact.personal_detail.hasOwnProperty('firstname') && contact.personal_detail.firstname){
					contact_text.push(contact.personal_detail.firstname);
				}
				if (contact.personal_detail.hasOwnProperty('surname') && contact.personal_detail.surname){
					contact_text.push(contact.personal_detail.surname);
				}
			}
			return contact_text.join(' ');
		});

		//message properties
		this.preview = ko.observable();
		this.envelopedate = ko.observable();

		if (obj)
			this.set(obj);
	}

	set(obj)
	{
		if (obj.hasOwnProperty('categories'))
			this.categories(obj.categories);

		if (obj.hasOwnProperty('query'))
			this.state_item(obj.query);

		if (obj.hasOwnProperty('state_item'))
			this.state_item(obj.state_item);

		if (obj.hasOwnProperty('message'))
			this.message(obj.message);

		if (obj.hasOwnProperty('contact'))
			this.contact(obj.contact);

		if (obj.hasOwnProperty('query_refnr'))
			this.query_refnr(obj.query_refnr);

		if (obj.hasOwnProperty('channel'))
			this.channel(obj.channel);

		if (obj.hasOwnProperty('assignee'))
			this.assignee(obj.assignee);

		if (obj.hasOwnProperty('state'))
			this.state(obj.state);

		if (obj.hasOwnProperty('message_properties')){
			if (obj.message_properties.hasOwnProperty('preview'))
				this.preview(obj.message_properties.preview);

			if (obj.message_properties.hasOwnProperty('envelopedate'))
				this.envelopedate(obj.message_properties.envelopedate);
		}
	}

}

export default Query;
