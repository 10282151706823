import template from './reply-message.html';

class ReplyMessageViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.to_address = ko.observable('');
		this.subject = ko.observable('');
		this.message_body = ko.observable('');
		this.attachments = ko.observable();

		let today = new Date();
		this.today = ko.observable(today.toISOString().split('T')[0]);
	};
	
	close()
	{
		this.dialog.close();
	}
};

class ReplyMessagePage
{
	constructor (bindings)
	{
		this.viewModel = new ReplyMessageViewModel(this);
		this.bindings = bindings;
	};

	init()
	{
		console.log('BINDINGS:', this.bindings.data);
		if (this.bindings.data)
		{
			this.viewModel.to_address(this.bindings.data.address.from);
		}
	}
};

export default {
	name: 'reply-message',
	dialog_class: ReplyMessagePage,
	template: template,
	provider: 'ps'
};
