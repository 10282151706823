import template from './shipments.html';

class ShipmentsViewModel
{
	constructor(params)
	{
		this.params = params;

		this.init();
	}

	async init()
	{

	}
}


export default {
	name: 'ko-shipments',
	viewModel: ShipmentsViewModel,
	module_type: 'ko',
	template: template
}