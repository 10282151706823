import template from './edit-add-filter.html';

class EditAddFilterViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.filter_name = ko.observable();
		this.public = ko.observable(false);
		this.from_date = ko.observable();
		this.to_date = ko.observable();
		this.title = ko.observable();

		// default dates for new filters, this will be populated if there are bindings (when editing)
		let today = new Date();
		let one_week = new Date();
		one_week.setDate(today.getDate() - 7);

		this.from_date = ko.observable(one_week.toISOString().split('T')[0]);
		this.to_date = ko.observable(today.toISOString().split('T')[0]);
	};
	
	close()
	{
		this.dialog.close();
	}
};

class EditAddFilterPage
{
	constructor (bindings)
	{
		this.viewModel = new EditAddFilterViewModel(this);
		this.bindings = bindings;
	};

	init()
	{
		if (this.bindings.data)
			this.viewModel.title('Edit Filter');
		else
			this.viewModel.title('Add Filter');
	}
};

// Register the dialog
export default {
	name: 'edit_add_filter',
	dialog_class: EditAddFilterPage,
	template: template,
	provider: 'ps'
};
