/**
 * @kind cache
 * @class QueryTypesLookup
 * @description Custom lookup cache for all available query types
 * @usage Grape.cache.fetch('QueryTypesLookup', function(data) { });
 */
export default {
	name: 'QueryTypesLookup',
	options: {
		schema: 'grape',
		table: 'v_lookup_value',
		filter:[{'field':'lookup_name', 'operator':'=', 'value':'QueryTypes'}]
	}
};
